<div
  *ngIf="{
    selectedOrigin: selectedOrigin$ | async,
    selectedDestination: selectedDestination$ | async,
    selectedFlightClassOfService: selectedFlightClassOfService$ | async
  } as data"
>
  <app-flight-selection-header [isReturn]="isReturn" (onModify)="onClickModify()"></app-flight-selection-header>
  <div aria-label="Content section" class="tw-mt-[30px] tw-mb-[110px]">
    <!--Error-->
    <div class="tw-items-center tw-px-3.75">
      <div class="scoot-container tw-mx-auto">
        <div *ngIf="errorMessages$ | async as errorMessages">
          <div aria-label="Errors section" *ngIf="errorMessages.length" class="tw-flex tw-pb-7 tw-flex-col">
            <div
              *ngFor="let error of errorMessages"
              class="tw-flex-1 tw-p-5 tw-bg-errors-pink tw-rounded-2xl tw-border tw-items-start tw-gap-2.5 tw-inline-flex"
            >
              <div class="tw-w-6 tw-h-6 tw-relative">
                <img aria-label="Error icon" class="tw-mx-auto tw-cursor-pointer" src="assets/icons/svg/error.svg" />
              </div>
              <div
                class="tw-grow tw-shrink tw-basis-0 tw-text-stone-900 tw-text-base tw-font-medium tw-leading-normal"
                [innerHTML]="error | translate | safe : 'html'"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Departing-->
    <div aria-label="Departing" id="{{ flightSelectionTypes.Departing }}" *ngIf="canManageDepartFlight">
      <div class="tw-items-center tw-px-3.75">
        <div class="scoot-container tw-mx-auto">
          <!--Title-->
          <h2 class="text-title">
            <p
              [ngClass]="{
                'tw-h2-sm': isMobile,
                'tw-h2-lg': !isMobile
              }"
            >
              <span aria-label="Departing title">
                {{ 'label.book-flight-selection-title.departing.label' | translate }}
              </span>
              -
              <span aria-label="Departing title station">
                {{ data.selectedOrigin?.stationCode }} to
                {{ data.selectedDestination?.stationCode }}
              </span>
            </p>
          </h2>
          <!--SFS notification-->
          <app-flight-selection-sfs-notification
            *ngIf="departScootForsureTrip$ | async as trip"
            [selectedFlightType]="flightSelectionTypes.Departing"
            [maxAvailableSeats]="trip.maxAvailableSeats"
            [isVisible]="trip.maxAvailableSeats > 0 && !this.isChangeFlightFlow"
          >
          </app-flight-selection-sfs-notification>
        </div>
      </div>
      <!--Date and Toggle-->
      <div aria-label="Departing date & toggle selection section" [hidden]="hasDepartFlightSelected">
        <!--Date Paginator-->
        <app-flight-selection-date-paginator
          *ngIf="{
            departDate: selectedDepartDate$ | async,
            returnDate: selectedReturnDate$ | async
          } as date"
          [isReturn]="isReturn"
          [terminationDate]="profile?.terminationDate ?? ''"
          [selectedDepartDate]="date.departDate"
          [selectedReturnDate]="date.returnDate"
          [selectedFlightType]="flightSelectionTypes.Departing"
          [selectedClassOfService]="selectedFlightClassOfService"
          [flightAvailabilityRequest]="flightAvailabilityRequest"
          [dateSelected]="date.departDate"
        ></app-flight-selection-date-paginator>
        <!--Select Flight Time/Toggle (Economy and ScootPlus) -->
        <app-flight-selection-toggle
          [selectedFlightType]="flightSelectionTypes.Departing"
          [bookingFlightType]="bookingFlightType"
        >
        </app-flight-selection-toggle>
      </div>
      <!--Flight Result-->
      <app-flight-selection-result
        *ngIf="{
          trip: departTrip$ | async,
          selectedData: selectedDepartingFlightData$ | async
        } as flight"
        [trip]="flight.trip ?? undefined"
        [selectedFlightType]="flightSelectionTypes.Departing"
        [selectedFlightClassOfService]="data.selectedFlightClassOfService"
        [selectedFlightData]="flight.selectedData"
        [bookingFlightType]="bookingFlightType"
        [totalVoucherPoints]="totalVoucherPoints"
        [isValidFlightDate]="isValidDepartDate"
      >
      </app-flight-selection-result>
    </div>
    <!--Returning-->
    <div aria-label="Returning" id="{{ flightSelectionTypes.Returning }}" *ngIf="isReturn">
      <!--Line separator-->
      <div class="tw-items-center" *ngIf="canManageDepartFlight">
        <div class="scoot-container tw-mx-auto">
          <hr class="tw-my-[2.5rem]" />
        </div>
      </div>
      <div class="tw-items-center tw-px-3.75">
        <div class="scoot-container tw-mx-auto">
          <!--Title-->
          <h2 class="text-title">
            <p
              [ngClass]="{
                'tw-h2-sm': isMobile,
                'tw-h2-lg': !isMobile
              }"
            >
              <span aria-label="Returning title">
                {{ 'label.book-flight-selection-title.returning.label' | translate }}
              </span>
              -
              <span aria-label="Returning title station">
                {{ data.selectedDestination?.stationCode }} to
                {{ data.selectedOrigin?.stationCode }}
              </span>
            </p>
          </h2>
          <!--SFS notification-->
          <app-flight-selection-sfs-notification
            *ngIf="returnScootForsureTrip$ | async as trip"
            [selectedFlightType]="flightSelectionTypes.Returning"
            [maxAvailableSeats]="trip.maxAvailableSeats"
            [isVisible]="trip.maxAvailableSeats > 0 && !this.isChangeFlightFlow"
          >
          </app-flight-selection-sfs-notification>
        </div>
      </div>
      <div aria-label="Returning date & toggle selection section" [hidden]="hasReturnFlightSelected">
        <!--Date Paginator-->
        <app-flight-selection-date-paginator
          *ngIf="{
            departDate: selectedDepartDate$ | async,
            returnDate: selectedReturnDate$ | async
          } as date"
          [isReturn]="isReturn"
          [terminationDate]="profile?.terminationDate ?? ''"
          [selectedDepartDate]="date.departDate"
          [selectedReturnDate]="date.returnDate"
          [selectedFlightType]="flightSelectionTypes.Returning"
          [selectedClassOfService]="selectedFlightClassOfService"
          [flightAvailabilityRequest]="flightAvailabilityRequest"
          [dateSelected]="date.returnDate"
        ></app-flight-selection-date-paginator>
        <!--Select Flight Time/Toggle (Economy and ScootPlus) -->
        <app-flight-selection-toggle
          [selectedFlightType]="flightSelectionTypes.Returning"
          [bookingFlightType]="bookingFlightType"
        >
        </app-flight-selection-toggle>
      </div>
      <!--Flight Result-->
      <app-flight-selection-result
        *ngIf="{
          trip: returnTrip$ | async,
          selectedData: selectedReturningFlightData$ | async
        } as flight"
        [trip]="flight.trip ?? undefined"
        [selectedFlightType]="flightSelectionTypes.Returning"
        [selectedFlightClassOfService]="data.selectedFlightClassOfService"
        [selectedFlightData]="flight.selectedData"
        [totalVoucherPoints]="totalVoucherPoints"
        [isValidFlightDate]="isValidReturnDate"
      >
      </app-flight-selection-result>
    </div>
  </div>
  <div aria-label="Footer section" class="footer tw-px-3.75">
    <div class="scoot-container tw-mx-auto">
      <div class="tw-flex tw-flex-col tw-gap-5 tw-justify-end">
        <div class="tw-flex tw-px-5 tw-mt-auto tw-gap-2.5 xs:tw-self-end xs:tw-px-0">
          <button
            aria-label="Next Page button"
            class="tw-btn tw-btn-primary footer-btn xs:tw-w-36"
            (click)="onNextPage()"
            [disabled]="!hasFlightsSelected || !isValidFlightDate || isSelectedFlightDataForChange || hasErrors"
          >
            {{ 'label.button.next-page.label' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-flight-search-modal
  *ngIf="isValidFormData && bookingFlightType === bookingFlightTypeEnum.NewBooking"
  [showModal]="showFlightSearchModal"
  (closeModal)="onCloseModify()"
  (letsGo)="onClickLetsGoModify()"
></app-flight-search-modal>
