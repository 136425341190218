import { mockTranslationKeys } from '../../../../setupJest';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { Booking } from '../../booking/booking.model';
import { NotificationService } from '../../shared/components/notification/notification.service';
import { ManageBookingService } from '../manage-booking.service';
import { CancelFlightRequest, CloseCancelModalParams } from '../manage-booking.model';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-cancel-journey',
  templateUrl: './cancel-journey.component.html',
  styleUrls: ['./cancel-journey.component.scss']
})
export class CancelJourneyComponent implements OnInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();
  private readonly _booking$ = new BehaviorSubject<Booking | null>(null);
  private readonly _errorMessage$ = new BehaviorSubject<string | null>(null);

  readonly flightClassOfServiceCodeEnum = environment.classOfServices;

  _booking!: Booking | null;
  isCancelDepart: boolean = false;
  isCancelReturn: boolean = false;
  showForm: boolean = false;
  booking$!: Observable<Booking | null>;
  errorMessage$!: Observable<string | null>;
  
  @Input() showModal: boolean = false;
  @Input()
  get booking(): Booking | null {
    return this._booking;
  }
  set booking(booking: Booking | null) {
    this._booking = booking;

    if (booking) {
      this.retrieveBooking(booking);
    }
  }
  @Output() closeModal = new EventEmitter<CloseCancelModalParams | null>();

  constructor(
    private notificationService: NotificationService,
    private readonly manageBookingService: ManageBookingService,
  ) {}

  ngOnInit(): void {
    this.booking$ = this._booking$.asObservable();
    this.errorMessage$ = this._errorMessage$.asObservable();
    this._errorMessage$.next(null);
  }

  retrieveBooking(booking: Booking): void {
    this._booking$.next(booking);
    this.showForm = this.showModal;
    this.isCancelDepart = false;
    this.isCancelReturn = false;
  }

  onChecked(isCheck: boolean, flightType: string) {
    if (flightType === 'Depart') {
      this.isCancelDepart = !isCheck;
    }

    if (flightType === 'Return') {
      this.isCancelReturn = !isCheck;
    }
  }

  onProceed(booking: Booking) {
    let journeyKeys: string[] = [];
    let message: string = '';
    let departMessage: string = '';
    let returnMessage: string = '';
    if (this.isCancelDepart) 
      journeyKeys.push(booking.journeys[0].journeyKey ?? '');

    if (this.isCancelReturn && booking.journeys.length > 1) 
      journeyKeys.push(booking.journeys[1].journeyKey ?? '');

    if (this.isCancelDepart) {
      const depart = booking.journeys[0];
      departMessage = 
        `Flight from ${depart.departureStation.city} 
        to ${depart.arrivalStation.city} 
        on ${this.manageBookingService.formatDate(depart.designator.departure, 'date')} 
        at ${this.manageBookingService.formatDate(depart.designator.departure, 'time')} 
        has been cancelled.`
    }

    if (this.isCancelReturn) {
      const returnFlight = booking.journeys[1];
      returnMessage = 
        `Flight from ${returnFlight.departureStation.city} 
        to ${returnFlight.arrivalStation.city} 
        on ${this.manageBookingService.formatDate(returnFlight.designator.departure, 'date')} 
        at ${this.manageBookingService.formatDate(returnFlight.designator.departure, 'time')} 
        has been cancelled.`
    }

    if (this.isCancelDepart && (this.isCancelReturn && booking.journeys.length > 1)) {
      message = `${departMessage}</br>${returnMessage}`;
    }
    else {
      message = 
        this.isCancelDepart ? departMessage :
        this.isCancelReturn ? returnMessage : ''
    }

    //Cancel flight
    const cancelFlightRequest: CancelFlightRequest = {
      recordLocator: this.booking?.recordLocator ?? '',
      journeyKeys: journeyKeys
    };

    this._errorMessage$.next(null);
    this.manageBookingService
      .cancelFlight$(cancelFlightRequest)
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        if (res.isSuccess) {
          this.showForm = false;
          this.showNotification(message, true, journeyKeys)
        }
        else if (res.errorMessage == "CancellationFeeIsLowerPayAmount") {
          this._errorMessage$.next(`notification.text.cancel-booking.cancel.not-allowed`);
        }
        else {
          this._errorMessage$.next(`error.message.submit.defaultSystemError`);
        }
      });
  }

  onCloseModal(){
    this.showModal = false;
    this.showForm = false;
    this.closeModal.emit(null);
  }

  private showNotification(message: string, bookingIsCancelled: boolean, journeyKeys: string[]) {
    const self = this;
    this.notificationService.show({
      title: 'label.manage-booking.cancel-flight-notif-title.label',
      text: message,
      showConfirmButton: true,
      showCancelButton: false,
      cancelButtonText: "Ok",
      widthClass: 'tw-w-[40%]',
      textAlignClass: 'tw-text-left',
      callBack: function (isConfirm: boolean) {
        if (isConfirm) {
          const params = {
            bookingIsCancelled : bookingIsCancelled,
            journeyKeys: journeyKeys
          };
          self.closeModal.emit(params);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
