export const AppConstants = {
  EventCategory: 'Staff Travel',
  CompanyName: 'Scoot Staff Travel',
  DefaultUserEmail: 'test_user@flyscoot.com',
  OfwCityCodes: ['MNL', 'CRK'],
  SkipAuthorizationUrls: ['/home'],
  DefaultDisplayDateFormat: 'dd MMMM yyyy',
  DefaultDisplayBirthDateFormat: 'dd MMM yyyy',
  DefaultCurrencyCode: 'SGD',
  DefaultCurrencyDescription: 'Singapore Dollar',
  DefaultCountryCode: 'SG',
  DefaultCultureCode: 'en-sg',
  DefaultLanguange: 'en_UK',
  PassengerFirstNameMaxLimit: 32,
  PassengerLastNameMaxLimit: 32,
  ScootForSureValidMonths: 6,
  FlightMinimumHours: 2,
  SingaporeCode: 'SIN',
  CadetPilotMarkets: ['SIN', 'PER'],
  FixHeaderHeight: 145,
  IPSCFeeCode: 'IPSC',
  VoucherPaymentCode: 'VO',
  MobileWidth: {
    xs: 768,
    xxs: 320
  },
  VoucherStatus: {
    Available: 0,
    Redeemed: 1,
    Void: 2
  },
  CurrencyGroupType: {
    Popular: 1,
    Others: 0
  },
  DefaultWindowCutOff: {
    ManageBooking: 4,
    Addons: 2
  },
  SkipLoadingUrls: [
    'wait',
    'confirmation',
    'receiver',
    '/booking/add',
    '/session/token/refresh',
    '/resources/markets/destinations'
  ],
  CriticalAPIUrls: [
    '/resource/origins',
    '/resource/destinations',
    '/resource/stations',
    '/external/staff/profile/get'
  ]
};
