export enum ErrorEnum {
  Unauthorized = 401,
  SessionTimeout = 419,
  InternalServerError = 500,
  AuthenticationInvalid = 498 
}

export enum ErrorMessageEnum {
  SessionTimeout = 'The provided token has timed out',
  AuthenticationInvalid = 'Authentication is invalid',
  ErrorRetrievingStaffProfile = 'Error retrieving staff profile',
  WorkdayDowntime = 'Workday downtime'
}

export enum ErrorLabelEnum {
  FlightBelowMinimumTwoHoursAfterDeparture = 'label.book-flight-selection.error.flight-minimum-two-hours-after-departure',
  AllowedToSelectTravelBenificiariesForScootforsure = 'label.book-flight-selection.error.allowed-to-select-travel-benificiaries-for-scootforsure',
  InsufficientScootforsurePoints = 'label.book-flight-selection.error.insufficient-scootforsure-points',
  DefaultSystemError = 'error.message.submit.defaultSystemError',
}