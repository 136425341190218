import { DropdownMultiSelectComponent } from './shared/components/dropdown-multiselect/dropdown-multiselect.component';
import { FlightComponent } from './booking/flight/flight.component';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { HeaderItemsComponent } from './header/header-items/header-items.component';
import { CommonModule, DatePipe } from '@angular/common';
import { StaffTravelApiInterceptor } from './staff-travel-api.interceptor';
import { FaqComponent } from './faq/faq.component';
import { FlightSearchComponent } from './booking/flight/flight-search/flight-search.component';
import { DropdownComponent } from './shared/components/dropdown/dropdown.component';
import { ProfileComponent } from './profile/profile.component';
import { FlightSelectionComponent } from './booking/flight/flight-selection/flight-selection.component';
import { ErrorInputComponent } from './shared/components/error-input/error-input.component';
import { FlightSearchCalendarComponent } from './booking/flight/flight-search/flight-search-calendar/flight-search-calendar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlightSearchCalendarMobileModalComponent } from './booking/flight/flight-search/flight-search-calendar/flight-search-calendar-mobile-modal/flight-search-calendar-mobile-modal.component';
import { FlightSearchPassengerModalComponent } from './booking/flight/flight-search/flight-search-passenger-modal/flight-search-passenger-modal.component';
import { NotificationComponent } from './shared/components/notification/notification.component';
import { CurrencySymbolPipe } from './shared/pipes/currency-symbol/currency-symbol.pipe';
import { ProgressionBarComponent } from './progression-bar/progression-bar.component';
import { ClickOutsideDirective } from './shared/directives/click-outside/click-outside.directive';
import { FlightSelectionDatePaginatorComponent } from './booking/flight/flight-selection/flight-selection-date-paginator/flight-selection-date-paginator.component';
import { FlightSearchModalComponent } from './booking/flight/flight-search/flight-search-modal/flight-search-modal.component';
import { FlightSelectionResultComponent } from './booking/flight/flight-selection/flight-selection-result/flight-selection-result.component';
import { FlightCardComponent } from './booking/flight/flight-selection/flight-selection-result/flight-card/flight-card.component';
import { FlightFareComponent } from './booking/flight/flight-selection/flight-selection-result/flight-card/flight-fare/flight-fare.component';
import { FlightDetailComponent } from './booking/flight/flight-selection/flight-selection-result/flight-card/flight-detail/flight-detail.component';
import { FlightFareDetailComponent } from './booking/flight/flight-selection/flight-selection-result/flight-card/flight-fare/flight-fare-detail/flight-fare-detail.component';
import { ModalComponent } from './shared/components/modal/modal.component';
import { ProfileResolver } from './profile/profile-resolver';
import { FlightDetailViewComponent } from './booking/flight/flight-selection/flight-selection-result/flight-card/flight-detail/flight-detail-view/flight-detail-view.component';
import { PaymentsModule } from '@it-application-hub/payments';
import { OfwDeclarationComponent } from './booking/ofw-declaration/ofw-declaration.component';
import { VoucherCompoennt } from './booking/voucher/voucher.component';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { FocCreditComponent } from './booking/foc-credit/foc-credit.component';
import { FlightPaymentComponent } from './booking/flight/flight-payment/flight-payment.component';
import { ProfileMyVoucherComponent } from './profile/profile-my-voucher/profile-my-voucher.component';
import { ProfileDetailsComponent } from './profile/profile-details/profile-details.component';
import { ProfileFocEntitlementComponent } from './profile/profile-foc-entitlement/profile-foc-entitlement.component';
import { LoaderComponent } from './loader/loader.component';
import { WaitComponent } from './wait/wait.component';
import { AddOnsModule } from '@it-application-hub/add-ons';
import { PaymentComponent } from './booking/payment/payment.component';
import { BookingsModule } from '@it-application-hub/bookings';
import { LoaderInterceptor } from './loader/loader.interceptor';
import { ManageBookingComponent } from './manage-booking/manage-booking.component';
import { FlightItemComponent } from './manage-booking/flight-item/flight-item.component';
import { ProfileTravelBeneficiariesComponent } from './profile/profile-travel-beneficiaries/profile-travel-beneficiaries.component';
import { GenderDescriptorPipe } from './shared/pipes/gender-descriptor/gender-descriptor.pipe';
import { YesNoDescriptorPipe } from './shared/pipes/yes-no-descriptor/yes-no-descriptor.pipe';
import { ErrorComponent } from './error/error.component';
import { ErrorInterceptor } from './error/error.interceptor';
import { PaginationBarComponent } from './pagination-bar/pagination-bar.component';
import { SafePipe } from './shared/pipes/dom-sanitizer/safe.pipe';
import { MinuteSecondsPipe } from './shared/pipes/minute-seconds/minute-seconds.pipe';
import { ConfirmationComponent } from './booking/confirmation/confirmation.component';
import { PaymentReceiverComponent } from './booking/payment-receiver/payment-receiver.component';
import { SessionExpiringComponent } from './session/session-expiring/session-expiring.component';
import { SessionExpiredComponent } from './session/session-expired/session-expired.component';
import { CancelJourneyComponent } from './manage-booking/cancel-journey/cancel-journey.component';
import { CurrencySelectionComponent } from './header/currency-selection/currency-selection.component';
import { SessionExpireInterceptor } from './session/session-expire.interceptor';
import { SessionInterceptor } from './session/session.interceptor';
import { FlightSelectionToggleComponent } from './booking/flight/flight-selection/flight-selection-toggle/flight-selection-toggle.component';
import { FlightSelectionHeaderComponent } from './booking/flight/flight-selection/flight-selection-header/flight-selection-header.component';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { TooltipComponent } from './shared/components/tooltip/tooltip.component';
import { DropdownModalComponent } from './shared/components/dropdown/dropdown-modal/dropdown-modal.component';
import { FlightSelectionSfsNotificationComponent } from './booking/flight/flight-selection/flight-selection-sfs-notification/flight-selection-sfs-notification.component';

function HttpLoaderFactory(httpBackend: HttpBackend) {
  return new TranslateHttpLoader(new HttpClient(httpBackend), './assets/i18n/', '.json');
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.singleSignOn.clientId,
      authority: environment.singleSignOn.authority,
      redirectUri: environment.singleSignOn.redirectUri,
      postLogoutRedirectUri: environment.singleSignOn.postLogoutRedirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: '/login-failed'
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HeaderItemsComponent,
    FaqComponent,
    ProfileComponent,
    ProfileTravelBeneficiariesComponent,
    ProfileMyVoucherComponent,
    ProfileDetailsComponent,
    ProfileFocEntitlementComponent,
    FlightComponent,
    FlightSearchComponent,
    FlightSearchCalendarComponent,
    FlightSearchCalendarMobileModalComponent,
    FlightSearchPassengerModalComponent,
    FlightSearchModalComponent,
    FlightSelectionComponent,
    FlightSelectionDatePaginatorComponent,
    FlightSelectionResultComponent,
    FlightSelectionHeaderComponent,
    FlightSelectionToggleComponent,
    FlightCardComponent,
    FlightFareComponent,
    FlightDetailComponent,
    FlightFareDetailComponent,
    FlightDetailViewComponent,
    FlightItemComponent,
    FlightPaymentComponent,
    OfwDeclarationComponent,
    VoucherCompoennt,
    FocCreditComponent,
    PaymentComponent,
    ManageBookingComponent,
    ConfirmationComponent,
    DropdownMultiSelectComponent,
    DropdownComponent,
    DropdownModalComponent,
    ClickOutsideDirective,
    ErrorInputComponent,
    NotificationComponent,
    ModalComponent,
    ProgressionBarComponent,
    PaginationBarComponent,
    ErrorComponent,
    WaitComponent,
    LoaderComponent,
    PaymentReceiverComponent,
    SessionExpiringComponent,
    SessionExpiredComponent,
    GenderDescriptorPipe,
    YesNoDescriptorPipe,
    SafePipe,
    MinuteSecondsPipe,
    CurrencySymbolPipe,
    CancelJourneyComponent,
    CurrencySelectionComponent,
    SpinnerComponent,
    TooltipComponent,
    FlightSelectionSfsNotificationComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    NgbModule,
    AddOnsModule,
    BookingsModule,
    PaymentsModule,
    MsalModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en-sg',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: 'baseUrl',
      useValue: environment.baseUrl
    },
    ProfileResolver,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StaffTravelApiInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionExpireInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
