import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalService } from '../../shared/components/modal/modal.service';
import { MsalAuthService } from '../../shared/service/msal-auth/msal-auth.service';
import { PageRoute } from '../../shared/enums/page-routes.enum';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionExpiredComponent {
  constructor(
    private modalService: ModalService,
    private msalAuthService: MsalAuthService,
    private sessionService: SessionService,
  ) {}

  logInAgain(): void {
    this.modalService.hide();
    if (this.msalAuthService.hasSSOLogin && !this.msalAuthService.authenticated){
      this.sessionService.clearSessionStorage();
      this.msalAuthService.login();
    }
    else {
      this.sessionService.clearSessionInfoFromLocalStorage();
      window.location.href = PageRoute.Home
    }
  }

  clickPopupBackgroundEvent(): void {}
}
