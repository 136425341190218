import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  NgbDate,
  NgbDatepicker
} from '@ng-bootstrap/ng-bootstrap';
import { FlightSearchService } from '../flight-search.service';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { AppConstants } from '../../../../app.constants';
import { DisabledDate } from '../../../flight/flight.model';

type MarkDisabledFunctionDef = (date: NgbDate) => boolean;

@Component({
  selector: 'app-flight-search-calendar',
  templateUrl: './flight-search-calendar.component.html',
  styleUrls: ['./flight-search-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightSearchCalendarComponent implements OnInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  flightSearchForm!: FormGroup;
  minDate!: NgbDate;
  maxDate!: NgbDate;
  departDate!: NgbDate | null;
  returnDate!: NgbDate | null;
  hoveredDate!: NgbDate | null;
  disabledButton = true;
  showWeekDays = true;
  navigation = 'select';
  displayMonths = 2;
  disabledDates: DisabledDate[] = [];

  _showDatepicker!: boolean;
  _terminationDate!: string | null;

  markDisabled!: MarkDisabledFunctionDef;

  @Input()
  get showDatepicker(): boolean {
    return this._showDatepicker;
  }
  set showDatepicker(value: boolean) {
    this._showDatepicker = value;
    if (this._showDatepicker) this.initializeCalendar();
  }
  @Input()
  get terminationDate(): string | null {
    return this._terminationDate;
  }
  set terminationDate(value: string | null) {
    this._terminationDate = value;
    this.initMarkDisabled();
  }
  @Input() departureDateControl!: AbstractControl<string | null>;
  @Input() returnDateControl!: AbstractControl<string | null>;
  @Output() onCloseCalendar = new EventEmitter<void>();
  @Output() isDisabledButton = new EventEmitter<boolean>();

  @ViewChild('datePicker') datePicker!: NgbDatepicker;
  @ViewChild('calendarElement') calendarElement!: ElementRef;
  @ViewChild('datePickerElement') datePickerElement!: ElementRef;

  constructor(
    private flightSearchService: FlightSearchService
  ) {
    const today = new Date();
    this.minDate = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
    this.maxDate = new NgbDate(today.getFullYear() + 1, today.getMonth() + 1, today.getDate());
    this.flightSearchForm = this.flightSearchService.flightSearchForm;
  }

  ngOnInit(): void {
    this.initializeCalendar();
    this.initMarkDisabled();
    this.validateIfDisabledButton();
  }

  setHoveredDate(date: NgbDate | null) {
    this.hoveredDate = date;
  }

  isSameDaySelected(): boolean {
    return this.departDate !== null && this.returnDate !== null && this.departDate.equals(this.returnDate);
  }

  isDepartDaySelected(date: NgbDate): boolean {
    return this.departDate !== null && this.departDate?.equals(date);
  }

  isReturnDaySelected(date: NgbDate): boolean {
    if (this.returnDate && this.isReturn) {
      return this.returnDate?.equals(date) && this.returnDate?.after(this.departDate);
    }

    return false;
  }

  isSelected(date: NgbDate): boolean {
    return this.isDepartDaySelected(date) || this.isReturnDaySelected(date);
  }

  isHovered(date: NgbDate): boolean {
    const isHovered =
      this.isReturn &&
      this.departDate &&
      !this.returnDate &&
      this.hoveredDate &&
      date.after(this.departDate) &&
      date.before(this.hoveredDate);

    return isHovered || false;
  }

  isInside(date: NgbDate): boolean {
    const isInside = this.isReturn && this.returnDate && date.after(this.departDate) && date.before(this.returnDate);

    return isInside || false;
  }

  isRange(date: NgbDate): boolean {
    return (
      this.isReturn &&
      (date.equals(this.departDate) ||
        (this.returnDate && date.equals(this.returnDate)) ||
        this.isInside(date) ||
        this.isHovered(date))
    );
  }

  isMonthYearSelected(date: NgbDate, datepicker: NgbDatepicker): boolean {
    const firstMonth = datepicker.state.months[0];
    return date.month === firstMonth.month && date.year === firstMonth.year;
  }

  onDateSelection(date: NgbDate | null) {
    if (date) {
      if (this.canManageDepartFlight && !this.departDate && !this.returnDate) {
        this.departDate = date;
        this.flightSearchService.calendar.departDate = this.formatDate(date);
      }
      else if (
        (this.canManageDepartFlight && this.isReturn && this.returnDate && this.departDate) ||
        (this.canManageDepartFlight && !this.isReturn)
      ) {
        this.departDate = date;
        this.returnDate = null;
        this.flightSearchService.calendar = {
          departDate: this.formatDate(date),
          returnDate: null
        };
      }
      else if (this.isReturn && (date.after(this.departDate) || date.equals(this.departDate))) {
        this.returnDate = date;
        this.flightSearchService.calendar.returnDate = this.formatDate(date);
      }
      else {
        if (this.canManageDepartFlight) {
          this.departDate = date;
          this.flightSearchService.calendar.departDate = this.formatDate(date);  
        }
      }
    }

    this.validateIfDisabledButton();
  }

  onClickClose() {
    this.setDateToOriginal();
    this._showDatepicker = false;
    this.onCloseCalendar.emit();
  }

  onClickReset() {
    this.returnDate = null;
    this.flightSearchService.calendar.returnDate = null;

    if (this.canManageDepartFlight) {
      this.departDate = null;
      this.flightSearchService.calendar.departDate = null;
    }

    this.onClickDone();
  }

  onClickDone() {
    this.setDateControl();
    this.showDatepicker = false;
    this.onCloseCalendar.emit();
  }

  validateIfDisabledButton() {
    this.disabledButton = !this.departDate && !this.returnDate;
    this.isDisabledButton.emit(this.disabledButton);
  }

  setDateToOriginal() {
    this.departDate = this.flightSearchService.formatToNgbDate(this.departureDateControl.value ?? '');
    this.returnDate = this.flightSearchService.formatToNgbDate(this.returnDateControl.value ?? '');
    this.flightSearchService.calendar = {
      returnDate: this.returnDateControl.value,
      departDate: this.departureDateControl.value
    };

    this.validateIfDisabledButton();
  }

  private initializeCalendar() {
    if (this.isMobile) {
      this.showWeekDays = false;
      this.navigation = 'none';
      this.displayMonths = 12;
    } else {
      this.showWeekDays = true;
      this.navigation = 'select';
      this.displayMonths = 2;
    }

    if (this.departureDateControl) {
      this.departDate = this.flightSearchService.formatToNgbDate(this.departureDateControl?.value ?? '');
    }

    if (this.returnDateControl) {
      this.returnDate = this.flightSearchService.formatToNgbDate(this.returnDateControl?.value ?? '');
    }

    this.validateIfDisabledButton();
  }

  private initMarkDisabled() {
    this.markDisabled = (date: NgbDate): boolean => {
      let isDisabledByTerminationDate = false;
      
      if (this.terminationDate) {
        const terminationDate = new Date(this.terminationDate);
        const formatTerminationDate = new NgbDate(terminationDate.getFullYear(), terminationDate.getMonth() + 1, terminationDate.getDate());
        isDisabledByTerminationDate = date.after(formatTerminationDate);  
      }

      const isDisabledByDate = this.disabledDates.some((x) => new NgbDate(x.year, x.month, x.day).equals(date));

      return isDisabledByDate || isDisabledByTerminationDate;
    };
  }

  private setDateControl() {
    const departDate = this.formatDate(this.departDate);
    if (this.departureDateControl.value !== departDate) {
      this.departureDateControl.setValue(departDate);
      this.departureDateControl.markAsDirty();
    }
    
    if (this.isReturn) {
      const returnDate = this.formatDate(this.returnDate);
      if (this.returnDateControl.value !== returnDate) {
        this.returnDateControl.setValue(returnDate);
        this.returnDateControl.markAsDirty();
      }
    }
  }

  private formatDate(value: NgbDate | null): string | null {
    const date = this.flightSearchService.formatNgbDateToDate(value);
    const formattedDate = this.flightSearchService.formatDate(date, AppConstants.DefaultDisplayDateFormat);
    return formattedDate;
  }

  get isMobile(): boolean {
    return window.innerWidth < AppConstants.MobileWidth.xs;
  }

  get isReturn(): boolean {
    return this.flightSearchForm.get('isReturn')?.value;
  }

  get canManageDepartFlight(): boolean {
    return this.flightSearchService.getCanManageFirstJourney() ?? true;
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target: HTMLElement) {
    if (
      !this.calendarElement.nativeElement.contains(target) &&
      !target.ariaLabel?.includes('Depart date field') && 
      !target.ariaLabel?.includes('Return date field') &&
      this.showDatepicker
    ) {
      this.onClickDone();
    }
  }
  
  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
