import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  fromEvent,
  of,
  take,
  takeUntil
} from 'rxjs';
import { Router } from '@angular/router';
import { Booking, UserContactInformation } from '../../booking.model';
import {
  ChangeFlightDataRequest,
  ChangeFlightRequest,
  FlightAvailabilityRequest,
  FlightAvailabilityResponse,
  FlightSelectedData,
  ScootForSureTrips
} from '../flight.model';
import { FlightSearchService } from '../flight-search/flight-search.service';
import { AppConstants } from '../../../app.constants';
import { FlightService } from '../flight.service';
import {
  BookingFlightTypeEnum,
  FlightClassOfServiceEnum,
  FlightFareTypeCodeEnum,
  FlightFareTypeEnum,
  FlightSelectionTypeEnum
} from '../flight.enum';
import { SessionService } from '../../../session/session.service';
import { BookingService } from '../../booking.service';
import { Market } from '../../../shared/model/resources.model';
import { PageRoute } from '../../../shared/enums/page-routes.enum';
import { ResourceService } from '../../../shared/service/resources/resources.service';
import { ProfileService } from '../../../profile/profile.service';
import { StaffProfile, VoucherList } from '../../../profile/profile.model';
import { NotificationData } from '../../../shared/components/notification/notification.model';
import { NotificationService } from '../../../shared/components/notification/notification.service';
import { HeaderService } from '../../../header/header.service';
import { environment } from '../../../../environments/environment';
import { FlightSelectionErrorHandlerService } from './flight-selection-error-handler.service';
import { ErrorLabelEnum } from '../../../error/error.enum';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-flight-selection',
  templateUrl: './flight-selection.component.html',
  styleUrls: ['./flight-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightSelectionComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly _destroy$ = new Subject<void>();
  private readonly _departTrip$ = new BehaviorSubject<Booking | null>(null);
  private readonly _returnTrip$ = new BehaviorSubject<Booking | null>(null);
  private readonly _departScootForsureTrip$ = new BehaviorSubject<ScootForSureTrips | null>(null);
  private readonly _returnScootForsureTrip$ = new BehaviorSubject<ScootForSureTrips | null>(null);

  readonly flightSelectionTypes = FlightSelectionTypeEnum;
  readonly flightClassOfServices = FlightClassOfServiceEnum;
  readonly bookingFlightTypeEnum = BookingFlightTypeEnum;

  errorMessages$!: Observable<string[] | null>;
  departTrip$!: Observable<Booking | null>;
  returnTrip$!: Observable<Booking | null>;
  departScootForsureTrip$!: Observable<ScootForSureTrips | null>;
  returnScootForsureTrip$!: Observable<ScootForSureTrips | null>;
  selectedDepartDate$?: Observable<Date | null>;
  selectedReturnDate$?: Observable<Date | null>;
  selectedOrigin$!: Observable<Market | undefined>;
  selectedDestination$!: Observable<Market | undefined>;
  selectedDepartingFlightData$!: Observable<FlightSelectedData | null>;
  selectedReturningFlightData$!: Observable<FlightSelectedData | null>;
  selectedFlightClassOfService$!: Observable<FlightClassOfServiceEnum>;
  selectedPassengers: number[] = [];

  hasDepartFlightSelected = false;
  hasReturnFlightSelected = false;
  selectedDepartingFlightData!: FlightSelectedData | null;
  selectedReturningFlightData!: FlightSelectedData | null;
  selectedFlightClassOfService!: FlightClassOfServiceEnum;

  scootForSureValidMonths = AppConstants.ScootForSureValidMonths;
  isValidDepartDate = false;
  isValidReturnDate = false;
  isSelectedDepartDataForChange = false;
  isSelectedReturnDataForChange = false;
  isValidFormData = false;

  profile!: StaffProfile | null;
  voucherList!: VoucherList;
  userContactInformation!: UserContactInformation | undefined;
  totalVoucherPoints = 0;
  windowInnerWitdh = window.innerWidth;

  showFlightSearchModal = false;
  origFormData!: any;
  flightAvailabilityRequest!: FlightAvailabilityRequest | null;

  constructor(
    private flightSearchService: FlightSearchService,
    private flightService: FlightService,
    private bookingService: BookingService,
    private profileService: ProfileService,
    private resourceService: ResourceService,
    private sessionService: SessionService,
    private notificationService: NotificationService,
    private headerService: HeaderService,
    private errorHandlerService: FlightSelectionErrorHandlerService,
    private datePipe: DatePipe,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isValidFormData = this.flightSearchService.getFormData() !== undefined;
    if (!this.isValidFormData) this.router.navigateByUrl(PageRoute.Home);

    this.departTrip$ = this._departTrip$.asObservable();
    this.returnTrip$ = this._returnTrip$.asObservable();
    this.departScootForsureTrip$ = this._departScootForsureTrip$.asObservable();
    this.returnScootForsureTrip$ = this._returnScootForsureTrip$.asObservable();

    this.selectedFlightClassOfService = this.flightService.selectedFlightClassOfService;
    this.errorMessages$ = this.errorHandlerService.errors$;
    this.selectedDepartDate$ = this.flightService.selectedDepartDate$;
    this.selectedReturnDate$ = this.flightService.selectedReturnDate$;
    this.selectedOrigin$ = this.flightService.selectedOrigin$;
    this.selectedDestination$ = this.flightService.selectedDestination$;
    this.selectedDepartingFlightData$ = this.flightService.selectedDepartingFlightData$;
    this.selectedReturningFlightData$ = this.flightService.selectedReturningFlightData$;
    this.selectedFlightClassOfService$ = this.flightService.selectedClassOfService$;
    this.selectedPassengers = this.passengers;

    this.flightService.selectedDepartDate$.pipe(takeUntil(this._destroy$)).subscribe((date: Date | null) => {
      const formattedDate = this.flightSearchService.formatDate(date, AppConstants.DefaultDisplayDateFormat);
      const formattedDepartDate = this.flightSearchService.formatDate(
        this.departDate,
        AppConstants.DefaultDisplayDateFormat
      );
      if (date && formattedDate !== formattedDepartDate) {
        this.flightSearchService.flightSearchForm.get('departDate')?.setValue(formattedDate);
        this.flightSearchService.calendar.departDate = formattedDate;
        this.flightSearchService.setFormData(this.flightSearchService.flightSearchForm.value);
        this.flightService.setSelectedDepartingFlightData(null);
        if(!this.showFlightSearchModal) this.loadTrips();
      }
    });

    this.flightService.selectedReturnDate$.pipe(takeUntil(this._destroy$)).subscribe((date: Date | null) => {
      const formattedDate = this.flightSearchService.formatDate(date, AppConstants.DefaultDisplayDateFormat);
      const formattedReturnDate = this.flightSearchService.formatDate(
        this.returnDate,
        AppConstants.DefaultDisplayDateFormat
      );
      if (date && formattedDate !== formattedReturnDate) {
        this.flightSearchService.flightSearchForm.get('returnDate')?.setValue(formattedDate);
        this.flightSearchService.calendar.returnDate = formattedDate;
        this.flightSearchService.setFormData(this.flightSearchService.flightSearchForm.value);
        this.flightService.setSelectedReturningFlightData(null);
        if(!this.showFlightSearchModal) this.loadTrips();
      }
    });

    this.flightService.selectedOrigin$.pipe(takeUntil(this._destroy$)).subscribe((data: Market | undefined) => {
      if (data && data.stationCode !== this.origin) {
        this.flightSearchService.flightSearchForm.get('fromCityAirport')?.setValue(data.stationCode);
        this.flightSearchService.setFormData(this.flightSearchService.flightSearchForm.value);
        this.resetSelectedData();
      }
    });

    this.flightService.selectedDestination$.pipe(takeUntil(this._destroy$)).subscribe((data: Market | undefined) => {
      if (data && data.stationCode !== this.destination) {
        this.flightSearchService.flightSearchForm.get('toCityAirport')?.setValue(data.stationCode);
        this.flightSearchService.setFormData(this.flightSearchService.flightSearchForm.value);
        this.resetSelectedData();
      }
    });

    this.flightService.selectedPassengers$.pipe(takeUntil(this._destroy$)).subscribe((passengers: number[] = []) => {
      const withUpdatedPassengers = 
        this.selectedPassengers.length !== passengers.length ||
        !this.selectedPassengers.every((value, index) => value === passengers[index]);
      if (passengers && withUpdatedPassengers) {
        this.selectedPassengers = passengers;
        this.flightSearchService.flightSearchForm.get('passengers')?.setValue(passengers);
        this.flightSearchService.setFormData(this.flightSearchService.flightSearchForm.value);
        this.resetSelectedData();
      }
    });

    this.flightService.selectedClassOfService$
      .pipe(takeUntil(this._destroy$))
      .subscribe((value: FlightClassOfServiceEnum) => {
        if (value != this.selectedFlightClassOfService) {
          this.selectedFlightClassOfService = value;
          this.bookingService.setClassOfService(value);
          this.resetSelectedData();
          this.loadTrips();
        }
      });

    this.flightService.selectedDepartingFlightData$
      .pipe(takeUntil(this._destroy$))
      .subscribe((flight: FlightSelectedData | null) => {
        this.hasDepartFlightSelected = flight !== null;
        this.selectedDepartingFlightData = flight;
        this.validateSelectedDataForchangeFlight();
        if (this.hasDepartFlightSelected && !this.hasReturnFlightSelected)
          this.scrollIntoView(`${FlightSelectionTypeEnum.Returning}`);
      });

    this.flightService.selectedReturningFlightData$
      .pipe(takeUntil(this._destroy$))
      .subscribe((flight: FlightSelectedData | null) => {
        this.hasReturnFlightSelected = flight !== null;
        this.selectedReturningFlightData = flight;
        this.validateSelectedDataForchangeFlight();
        if (this.hasReturnFlightSelected && !this.hasDepartFlightSelected)
          this.scrollIntoView(`${FlightSelectionTypeEnum.Departing}`);
      });

    this.errorHandlerService.errors$.pipe(takeUntil(this._destroy$)).subscribe((errors) => {
      const hasErrors = errors && errors.length > 0;
      if (hasErrors) this.scrollToTop();
    });

    this.profileService.staffProfile$.pipe(takeUntil(this._destroy$)).subscribe((data) => {
      this.profile = data;
      if (data) {
        this.voucherList = data.voucherList;
        this.totalVoucherPoints = data.voucherList.totalPoints ?? 0;
        this.userContactInformation = this.profileService.getContactInformation(data);
      }
    });

    this.resourceService
      .getExternalRates$()
      .pipe(takeUntil(this._destroy$))
      .subscribe((exchangeRates) => {
        this.flightService.exchangeRates = exchangeRates;
      });

    fromEvent(window, 'resize')
      .pipe(takeUntil(this._destroy$), debounceTime(200), distinctUntilChanged())
      .subscribe(() => {
        if (this.isMobile && this.windowInnerWitdh != window.innerWidth) {
          this.setDatePaginatorDefault();
          this.scrollToTop();
        }
      });

    this.headerService.setCurrencyCode();
    this.flightService.setSelectedClassOfService(this.selectedFlightClassOfService);
    this.loadTrips();
  }

  ngAfterViewInit(): void {
    this.setDatePaginatorDefault();
  }

  //#region Modify
  onClickModify() {
    this.showFlightSearchModal = true;
  }

  onCloseModify(): void {
    this.showFlightSearchModal = false;
  }

  onClickLetsGoModify(): void {
    this.showFlightSearchModal = false;
    this.headerService.setCurrencyCode();

    const currentFormData = this.flightSearchService.flightSearchForm.getRawValue();
    const isLocationUpdated =
      this.origFormData['fromCityAirport'] !== currentFormData['fromCityAirport'] ||
      this.origFormData['toCityAirport'] !== currentFormData['toCityAirport'];
    const isDepartUpdated = this.origFormData['departDate'] !== currentFormData['departDate'];
    const isReturnUpdated = this.origFormData['returnDate'] !== currentFormData['returnDate'];
    const isPassengersUpdated = 
        this.selectedPassengers.length !== this.origFormData['passengers'] ||
        !this.selectedPassengers.every((value, index) => value === this.origFormData['passengers'][index]);

    if (isLocationUpdated || isPassengersUpdated || isDepartUpdated || isReturnUpdated) {
      if (isLocationUpdated || isPassengersUpdated) this.resetSelectedData();
      if (isDepartUpdated) this.flightService.setSelectedDepartingFlightData(null);
      if (this.isReturn && isReturnUpdated) this.flightService.setSelectedReturningFlightData(null);

      this.loadTrips();
    }
  }
  //#endregion

  //#region Submit
  onNextPage(): void {
    this.flightService.selectedFlightClassOfService = this.selectedFlightClassOfService;
    if (this.isChangeFlightFlow) {
      this.processChangeFlight();
    } else {
      this.processSellFlight();
    }
  }

  private processChangeFlight(): void {
    const self = this;
    const departData = this.flightService.changeFlightData.depart;
    const returnData = this.flightService.changeFlightData.return;
    const changeFlightRequest: ChangeFlightRequest[] = [];
    if (
      this.selectedDepartingFlightData &&
      (departData.journeyKey != this.selectedDepartingFlightData?.journeyKey ||
        !departData.fares?.some(
          (p) =>
            p.fareKey === (this.selectedDepartingFlightData?.fareKey ?? '') ||
            p.fareKey === (this.selectedDepartingFlightData?.fare.reference ?? '')
        ))
    ) {
      changeFlightRequest.push({
        fromJourneyKey: departData.journeyKey ?? '',
        journeyKey: this.selectedDepartingFlightData.journeyKey,
        fareKey: this.selectedDepartingFlightData.fareKey ?? '',
        standbyPriorityCode:
          this.selectedDepartingFlightData?.fare.type === FlightFareTypeEnum.Economy
            ? FlightFareTypeCodeEnum.Economy
            : this.selectedDepartingFlightData?.fare.type === FlightFareTypeEnum.ScootPlus
              ? FlightFareTypeCodeEnum.ScootPlus
              : ''
      });
    }

    if (
      this.isReturn &&
      returnData &&
      this.selectedReturningFlightData &&
      (returnData.journeyKey != this.selectedReturningFlightData?.journeyKey ||
        !returnData.fares?.some(
          (p) =>
            p.fareKey === (this.selectedReturningFlightData?.fareKey ?? '') ||
            p.fareKey === (this.selectedReturningFlightData?.fare.reference ?? '')
        ))
    ) {
      changeFlightRequest.push({
        fromJourneyKey: returnData?.journeyKey ?? '',
        journeyKey: this.selectedReturningFlightData.journeyKey,
        fareKey: this.selectedReturningFlightData.fareKey ?? '',
        standbyPriorityCode:
          this.selectedReturningFlightData?.fare.type === FlightFareTypeEnum.Economy
            ? FlightFareTypeCodeEnum.Economy
            : this.selectedReturningFlightData?.fare.type === FlightFareTypeEnum.ScootPlus
              ? FlightFareTypeCodeEnum.ScootPlus
              : ''
      });
    }

    if (changeFlightRequest.length > 0) {
      const request = {
        recordLocator: this.flightService.changeFlightData.recordLocator,
        isFoc: this.flightService.changeFlightData.isFocBooking,
        data: changeFlightRequest
      } as ChangeFlightDataRequest;

      const notification: NotificationData = {
        title: 'notification.title.notification',
        text: 'notification.text.manage-booking.change-flight-prepurchased-addons',
        showConfirmButton: true,
        showCancelButton: false,
        widthClass: 'tw-w-[40%]',
        textAlignClass: 'tw-text-left',
        callBack: function (isConfirm: boolean) {
          if (isConfirm) {
            self.bookingService
              .changeFlight$(request)
              .pipe(take(1))
              .subscribe((response) => {
                if (response.isSuccess) {
                  self.proceedForPayment(response.booking);
                } else {
                  self.errorHandlerService.emitError(`error.message.submit.defaultSystemError`);
                }
              });
          }
        }
      };

      this.notificationService.show(notification);
    }
  }

  private processSellFlight() {
    const tripCriteria = [
      {
        journeyKey: this.selectedDepartingFlightData?.journeyKey,
        fareKey: this.selectedDepartingFlightData?.fareKey,
        standbyPriorityCode:
          this.selectedDepartingFlightData?.fare.type === FlightFareTypeEnum.Economy
            ? FlightFareTypeCodeEnum.Economy
            : this.selectedDepartingFlightData?.fare.type === FlightFareTypeEnum.ScootPlus
              ? FlightFareTypeCodeEnum.ScootPlus
              : ''
      }
    ];

    if (this.isReturn) {
      tripCriteria.push({
        journeyKey: this.selectedReturningFlightData?.journeyKey,
        fareKey: this.selectedReturningFlightData?.fareKey,
        standbyPriorityCode:
          this.selectedReturningFlightData?.fare.type === FlightFareTypeEnum.Economy
            ? FlightFareTypeCodeEnum.Economy
            : this.selectedReturningFlightData?.fare.type === FlightFareTypeEnum.ScootPlus
              ? FlightFareTypeCodeEnum.ScootPlus
              : ''
      });
    }

    const request = {
      sellFlightRequest: {
        passengerCriteria: this.flightService.passengerCriteria,
        currencyCode:
          this.selectedFlightClassOfService === FlightClassOfServiceEnum.ScootForSure
            ? AppConstants.DefaultCurrencyCode
            : this.flightSearchService.flightSearchForm?.get('currencyCode')?.value,
        tripCriteria: tripCriteria,
        promotionCode: ''
      },
      passengers: this.flightService.passengers
    };

    this.bookingService
      .sellFlight$(request)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.isSuccess && response.booking) {
          this.proceedForPayment(response.booking);
        } else {
          this.errorHandlerService.emitError(`error.message.submit.defaultSystemError`);
        }
      });
  }

  private proceedForPayment(booking: Booking): void {
    if (this.userContactInformation && booking.sales)
      this.userContactInformation.sourceOrganization = booking.sales?.source.organizationCode;

    this.bookingService.setBookingDetail(
      {
        currencyCode: this.currencyCode,
        userContactInformation: this.userContactInformation,
        isFlightScootForSure: this.selectedFlightClassOfService === FlightClassOfServiceEnum.ScootForSure,
        isFlightWithScootPlus:
          this.selectedDepartingFlightData?.fare.type === FlightFareTypeEnum.ScootPlus ||
          this.selectedReturningFlightData?.fare.type === FlightFareTypeEnum.ScootPlus,
        totalCreditsRemaining: this.profile?.totalCreditsRemaining ?? 0,
        totalEntitlement: this.profile?.totalEntitlement ?? 0,
        vouchers: [],
        passengers: []
      },
      this.voucherList.vouchers,
      this.flightService.passengers
    );

    //Currency
    this.sessionService.setCurrencyCodeToSessionStorage(
      this.flightSearchService.getFormData()?.currencyCode ?? AppConstants.DefaultCurrencyCode
    );

    this.router.navigate([PageRoute.BookFlightPayment]);
  }
  //#endregion

  //#region Flight Availability
  private loadTrips() {
    this.errorHandlerService.clearErrors();

    this.origFormData = cloneDeep(this.flightSearchService.flightSearchForm.getRawValue());
    this.isValidDepartDate = true;
    this.isValidReturnDate = true;

    this.validateIfScootForSureFlightDateIsWithinSixMonths();

    const isValidDate = this.isValidDepartDate && this.isValidReturnDate;
    if (isValidDate) {
      this.getFlightAvailability();
    }
  }

  private getFlightAvailability() {
    this._departTrip$.next(null);
    this._returnTrip$.next(null);
    this._departScootForsureTrip$.next(null);
    this._returnScootForsureTrip$.next(null);

    this.flightAvailabilityRequest = this.getAvailabilityRequest();
    if (this.flightAvailabilityRequest) {
      this.bookingService
        .getAvailability$(this.flightAvailabilityRequest)
        .pipe(take(1))
        .subscribe((response: FlightAvailabilityResponse) => {
          if (response) {
            const departTrip = response.trips.find((x) => x.origin === this.origin);
            this._departTrip$.next(departTrip ?? null);

            const returnTrip = response.trips.find((x) => x.origin === this.destination);
            this._returnTrip$.next(returnTrip ?? null);

            const departScootForsureTrip = response.scootForSureTrips.find((x) => x.origin === this.origin);
            this._departScootForsureTrip$.next(departScootForsureTrip ?? null);

            const returnScootForsureTrip = response.scootForSureTrips.find((x) => x.origin === this.destination);
            this._returnScootForsureTrip$.next(returnScootForsureTrip ?? null);

            this.validateIfSelectNonScootForSureEligiblePassengers();
            this.validateIfScootForSurePointsIsSufficient(response.trips);
            this.flightService.setSelectedClassOfService(this.selectedFlightClassOfService);
          }
          return of(false);
        });
    }
  }

  private getAvailabilityRequest(): FlightAvailabilityRequest | null {
    if (!this.origin || !this.destination) {
      return null;
    }

    const trips = [
      {
        origin: this.origin,
        destination: this.destination,
        departureDate: `${this.datePipe.transform(this.departDate, 'yyyy-MM-dd')}T00:00:00.000Z`
      }
    ];

    if (this.isReturn) {
      trips.push({
        origin: this.destination,
        destination: this.origin,
        departureDate: `${this.datePipe.transform(this.returnDate, 'yyyy-MM-dd')}T00:00:00.000Z`
      });
    }

    const classOfService =
      this.selectedFlightClassOfService === FlightClassOfServiceEnum.ScootForSure
        ? environment.classOfServices.scootForSure
        : [environment.classOfServices.scootPlus, environment.classOfServices.economy];

    const request = {
      flightCriteria: trips,
      passengerCriteria: this.flightService.passengerCriteria,
      flightFare: { classOfService: classOfService },
      currencyCode: this.currencyCode
    } as FlightAvailabilityRequest;

    return request;
  }
  //#endregion

  //#region Validation
  private validateIfScootForSureFlightDateIsWithinSixMonths() {
    if (this.selectedFlightClassOfService === FlightClassOfServiceEnum.ScootForSure) {
      const validMonths = new Date();
      validMonths.setMonth(validMonths.getMonth() + this.scootForSureValidMonths);

      this.isValidDepartDate = this.departDate ? new Date(this.departDate) <= validMonths : false;

      if (this.isReturn) this.isValidReturnDate = this.returnDate ? new Date(this.returnDate) <= validMonths : false;
    }
  }

  private validateIfSelectNonScootForSureEligiblePassengers() {
    if (this.selectedFlightClassOfService === FlightClassOfServiceEnum.ScootForSure) {
      const passengers = this.flightService.passengers;
      if (passengers.filter((x) => !x.thanksEligibility).length > 0) {
        this.errorHandlerService.emitError(ErrorLabelEnum.AllowedToSelectTravelBenificiariesForScootforsure)
      }
    }
  }

  private validateIfScootForSurePointsIsSufficient(trips: Booking[]) {
    if (this.selectedFlightClassOfService === FlightClassOfServiceEnum.ScootForSure) {
      let isValid = true;
      const totalVoucherPoints = this.totalVoucherPoints;
      if (trips.length) {
        const departFares = trips[0]?.journeys.flatMap((r) => r.fares);
        const returnFares = this.isReturn ? trips[1]?.journeys.flatMap((r) => r.fares) : [];
        const departPoints = departFares.find(r => (r.points ?? 0) > 0)?.points ?? 0;
        const returnPoints = this.isReturn ? (returnFares.find(r => (r.points ?? 0) > 0)?.points ?? 0) : 0;
        isValid = totalVoucherPoints >= (departPoints + returnPoints);
      }

      if (!isValid) {
        this.errorHandlerService.emitError(ErrorLabelEnum.InsufficientScootforsurePoints)
      }
    }
  }

  private validateSelectedDataForchangeFlight() {
    if (this.isChangeFlightFlow && this.flightService.changeFlightData) {
      const departData = this.flightService.changeFlightData.depart.flightData;
      this.isSelectedDepartDataForChange =
        departData.classOfService === this.selectedDepartingFlightData?.classOfService &&
        (departData?.fareKey === this.selectedDepartingFlightData?.fare.reference ||
          departData?.fareKey === this.selectedDepartingFlightData?.fare.fareKey) &&
        departData.journeyKey === this.selectedDepartingFlightData?.journeyKey;

      if (this.isReturn) {
        const returnData = this.flightService.changeFlightData.return?.flightData;
        this.isSelectedReturnDataForChange =
          returnData?.classOfService === this.selectedReturningFlightData?.classOfService &&
          (returnData?.fareKey === this.selectedReturningFlightData?.fare.reference ||
            returnData?.fareKey === this.selectedReturningFlightData?.fare.fareKey) &&
          returnData?.journeyKey === this.selectedReturningFlightData?.journeyKey;
      }
    }
  }
  //#endregion

  private resetSelectedData() {
    this.flightService.setSelectedDepartingFlightData(null);
    if (this.isReturn) this.flightService.setSelectedReturningFlightData(null);
  }

  private scrollIntoView(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      setTimeout(() => {
        window.scroll({
          behavior: 'smooth',
          left: 0,
          top: element.offsetTop - AppConstants.FixHeaderHeight
        });
      });
    }
  }

  private focusIntoElement(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) element.focus();
  }

  private scrollToTop() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  private setDatePaginatorDefault() {
    setTimeout(() => {
      if (this.returnDate && this.isReturn) {
        const returnDate = new Date(this.returnDate);
        this.focusIntoElement(
          `${FlightSelectionTypeEnum.Returning} - ${
            returnDate.getMonth() + 1
          }-${returnDate.getDate()}-${returnDate.getFullYear()}`
        );
        this.scrollToTop();
      }

      if (this.departDate) {
        const departDate = new Date(this.departDate);
        this.focusIntoElement(
          `${FlightSelectionTypeEnum.Departing} - ${
            departDate.getMonth() + 1
          }-${departDate.getDate()}-${departDate.getFullYear()}`
        );
      }
    });
  }

  get isReturn(): boolean {
    return this.flightSearchService.getFormData()?.isReturn ?? false;
  }

  get origin(): string {
    return this.flightSearchService.getFormData()?.fromCityAirport ?? '';
  }

  get destination(): string {
    return this.flightSearchService.getFormData()?.toCityAirport ?? '';
  }

  get departDate(): Date | null {
    const departDate = this.flightSearchService.getFormData()?.departDate;
    return departDate ? new Date(departDate) : null;
  }

  get returnDate(): Date | null {
    const returnDate = this.flightSearchService.getFormData()?.returnDate;
    return returnDate ? new Date(returnDate) : null;
  }

  get passengers(): number[] {
    return this.flightSearchService.getFormData()?.passengers ?? [];
  }

  get currencyCode(): string {
    return this.sessionService.getCurrencyCodeFromSessionStorage();
  }

  get bookingFlightType(): BookingFlightTypeEnum | null {
    return this.bookingService.bookingType;
  }

  get isChangeFlightFlow(): boolean {
    return this.bookingService.bookingType === BookingFlightTypeEnum.ChangeFlight;
  }

  get canManageDepartFlight(): boolean {
    return this.flightSearchService.getCanManageFirstJourney() ?? true;
  }

  get isMobile(): boolean {
    return window.innerWidth < AppConstants.MobileWidth.xs;
  }

  get hasFlightsSelected(): boolean {
    return this.isReturn ? this.hasDepartFlightSelected && this.hasReturnFlightSelected : this.hasDepartFlightSelected;
  }

  get isValidFlightDate(): boolean {
    return this.isReturn ? this.isValidDepartDate && this.isValidReturnDate : this.isValidDepartDate;
  }

  get isSelectedFlightDataForChange(): boolean {
    if (this.isChangeFlightFlow) {
      return this.isReturn
        ? this.isSelectedDepartDataForChange && this.isSelectedReturnDataForChange
        : this.isSelectedDepartDataForChange;
    }
    return false;
  }

  get hasErrors(): boolean {
    return this.errorHandlerService.hasErrors();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
