import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  fromEvent,
  take,
  takeUntil
} from 'rxjs';
import { NotificationService } from '../../../../shared/components/notification/notification.service';
import { AppConstants } from '../../../../app.constants';
import { NotificationData } from '../../../../shared/components/notification/notification.model';
import { FlightSearchService } from '../../flight-search/flight-search.service';
import { FlightClassOfServiceEnum, FlightSelectionTypeEnum } from '../../flight.enum';
import { FlightService } from '../../flight.service';
import { FlightAvailabilityRequest, FlightSelectedData } from '../../flight.model';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { BookingService } from '../../../../booking/booking.service';
import { environment } from '../../../../../environments/environment';
import { BookingFlightLowfare, BookingFlightLowfareRequest } from '../../../../booking/booking.model';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-flight-selection-date-paginator',
  templateUrl: './flight-selection-date-paginator.component.html',
  styleUrls: ['./flight-selection-date-paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightSelectionDatePaginatorComponent implements OnInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();
  private readonly _dates$ = new BehaviorSubject<Date[] | null>(null);
  public dates$?: Observable<Date[] | null>;

  _dateSelected!: Date | null;
  _selectedClassOfService!: FlightClassOfServiceEnum | null;

  selectDate = false;
  hasDepartFlightSelected = false;
  hasReturnFlightSelected = false;
  departFlightSelected!: FlightSelectedData | null;
  returnFlightSelected!: FlightSelectedData | null;
  flightClassOfServiceEnum = FlightClassOfServiceEnum;
  windowInnerWitdh = window.innerWidth;

  @Input() isReturn!: boolean | false;
  @Input() terminationDate!: string;
  @Input() selectedDepartDate!: Date | null;
  @Input() selectedReturnDate!: Date | null;
  @Input() selectedFlightType!: FlightSelectionTypeEnum | null;
  @Input() flightAvailabilityRequest!: FlightAvailabilityRequest | null;
  @Input()
  get selectedClassOfService(): FlightClassOfServiceEnum | null {
    return this._selectedClassOfService;
  }
  set selectedClassOfService(value: FlightClassOfServiceEnum | null) {
    if (value !== this._selectedClassOfService) {
      this._selectedClassOfService = value;
      this.getLowfareEstimate();
    }
  }
  @Input()
  get dateSelected(): Date | null {
    return this._dateSelected;
  }
  set dateSelected(value: Date | null) {
    const date = value ? this.flightSearchService.formatDate(new Date(value), 'yyyy-MM-dd') : '';
    const dateSelected = this._dateSelected
      ? this.flightSearchService.formatDate(new Date(this._dateSelected), 'yyyy-MM-dd')
      : '';

    if (value && date !== dateSelected) {
      this._dateSelected = new Date(value);
      this.buildData(value);
    }
  }

  config = {
    ariaLabelDateFormat: 'M-d-yyyy',
    displayDateFormat: 'EEE, dd MMM',
    displayCount: 7,
    mobileDisplayCount: 14,
    maxNavAllowed: 2,
    disabledNavNextButton: false,
    disabledNavPrevButton: false,
    minDate: this.dateSelected ?? new Date(),
    maxDate: this.dateSelected ?? new Date()
  };

  currentDates!: Date[];
  currentStartDate!: Date | null;
  currentEndDate!: Date | null;
  bookingFlightLowfare!: BookingFlightLowfare | null;

  constructor(
    private flightService: FlightService,
    private flightSearchService: FlightSearchService,
    private bookingService: BookingService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.dates$ = this._dates$.asObservable();

    this.flightService.selectedDepartingFlightData$
      .pipe(takeUntil(this._destroy$))
      .subscribe((flight: FlightSelectedData | null) => {
        this.departFlightSelected = flight;
        if (flight !== this.departFlightSelected) {
          this.buildData(this.selectedDepartDate);
        }
      });

    this.flightService.selectedReturningFlightData$
      .pipe(takeUntil(this._destroy$))
      .subscribe((flight: FlightSelectedData | null) => {
        this.returnFlightSelected = flight;
        if (flight !== this.returnFlightSelected) {
          this.buildData(this.selectedReturnDate);
        }
      });

    this.dates$.pipe(takeUntil(this._destroy$)).subscribe((dates: Date[] | null) => {
      if (dates) {
        this.currentDates = dates;
        this.currentStartDate = dates[0];
        this.currentEndDate = dates[dates.length - 1];

        const prevDate = this.minusDays(dates[0], 1);
        this.config.disabledNavPrevButton = this.isBelowMinDate(prevDate);

        const nextDate = this.addDays(dates[dates.length - 1], 1);
        this.config.disabledNavNextButton = this.isAboveMaxDate(nextDate);

        //Scroll to selected date
        if (this.isMobile) {
          setTimeout(() => {
            if (this._dateSelected) {
              const id = `${this.selectedFlightType} - ${
                this._dateSelected.getMonth() + 1
              }-${this._dateSelected.getDate()}-${this._dateSelected.getFullYear()}`;
              const element = document.getElementById(id);

              if (element) {
                element.scrollIntoView({
                  behavior: 'smooth',
                  block: 'nearest',
                  inline: 'center'
                });
              }
              if (!this.selectDate) window.scroll({ top: 0, left: 0, behavior: 'smooth' });
              else this.selectDate = false;
            }
          });
        }
      }
    });

    fromEvent(window, 'resize')
      .pipe(takeUntil(this._destroy$), debounceTime(200), distinctUntilChanged())
      .subscribe(() => {
        if (this.windowInnerWitdh != window.innerWidth) {
          this.buildData(this._dateSelected);
          this.windowInnerWitdh = window.innerWidth;
        }
      });

    this.buildData(this._dateSelected);
  }

  isBelowMinDate(date: Date): boolean {
    return (
      new Date(`${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`) <
      new Date(
        `${this.config.minDate.getMonth() + 1}-${this.config.minDate.getDate()}-${this.config.minDate.getFullYear()}`
      )
    );
  }

  isAboveMaxDate(date: Date): boolean {
    return (
      new Date(`${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`) >
      new Date(
        `${this.config.maxDate.getMonth() + 1}-${this.config.maxDate.getDate()}-${this.config.maxDate.getFullYear()}`
      )
    );
  }

  isNotAvailableFlightDate(date: Date): boolean {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const thisDate = `${year}-${month}-${day}`;
    return this.flightSearchService.disabledDateList.includes(thisDate);
  }

  isPastTerminationDate(date: Date): boolean {
    if (this.terminationDate) {
      const terminationDate = new Date(this.terminationDate);
      const formatDate = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
      const formatTerminationDate = new NgbDate(
        terminationDate.getFullYear(),
        terminationDate.getMonth() + 1,
        terminationDate.getDate()
      );
      return formatDate.after(formatTerminationDate);
    }

    return false;
  }

  withSeat(value: Date): boolean {
    const date = this.flightSearchService.formatDate(value, 'yyyy-MM-dd');

    if (this.bookingFlightLowfare) {
      const lowFare = this.bookingFlightLowfare.lowFareSearchMarkets[0].lowFares.find(
        (p) => this.flightSearchService.formatDate(new Date(p.departureDate), 'yyyy-MM-dd') === date
      );
      return (lowFare?.fareAmount ?? 0) > 0;
    }

    return false;
  }

  isSelected(date: Date): boolean {
    let isSelected = false;

    if (this.dateSelected) {
      isSelected =
        this.dateSelected.getFullYear() === date.getFullYear() &&
        this.dateSelected.getMonth() + 1 === date.getMonth() + 1 &&
        this.dateSelected.getDate() === date.getDate();
    }

    return isSelected;
  }

  onDateSelection(date: Date) {
    if (
      !this.isBelowMinDate(date) &&
      !this.isAboveMaxDate(date) &&
      !this.isNotAvailableFlightDate(date) &&
      !this.isPastTerminationDate(date)
    ) {
      if (this.isValidSelectedDate(date)) {
        this._dateSelected = date;
        this.selectDate = true;

        if (this.selectedFlightType === FlightSelectionTypeEnum.Departing) {
          this.flightService.setSelectedDepartDate(date);
          this.selectedDepartDate = date;
        } else if (this.selectedFlightType === FlightSelectionTypeEnum.Returning) {
          this.flightService.setSelectedReturnDate(date);
          this.selectedReturnDate = date;
        }

        this.buildData(date);
      }
    }
  }

  onNavigateNext(): void {
    if (this.currentEndDate) {
      const startDate = this.addDays(this.currentEndDate, 1);
      this.buildDisplayDates(startDate);
      this.getLowfareEstimate();
    }
  }

  onNavigatePrev(): void {
    if (this.currentStartDate) {
      const startDate = this.minusDays(this.currentStartDate, this.config.displayCount);
      this.buildDisplayDates(startDate);
      this.getLowfareEstimate();
    }
  }

  buildData(date: Date | null): void {
    if (date) {
      this.setMinAndMaxDate();

      //Build dates to display
      if (this.isMobile) {
        this.buildMobileDisplayDates();
      } else {
        const startDate = this.minusDays(date, (this.config.displayCount / 2) | 0);
        this.buildDisplayDates(startDate);
      }
    }
  }

  private buildDisplayDates(date: Date): void {
    const dates: Date[] = [];

    for (let i = 1; i <= this.config.displayCount; i++) {
      dates.push(new Date(date));
      date = this.addDays(date, 1);
    }

    this.currentDates = dates;
    this.currentStartDate = dates[0];
    this.loadDates(dates);
  }

  private buildMobileDisplayDates(): void {
    const dates: Date[] = [];
    let date = this.dateSelected ? this.minusDays(this.dateSelected, 13) : this.config.minDate;

    do {
      dates.push(new Date(date));
      date = this.addDays(date, 1);
    } while (date <= this.config.maxDate);

    this.currentDates = dates;
    this.currentStartDate = dates[0];
    this.loadDates(dates);
  }

  private loadDates(dates: Date[]) {
    if (this.selectedClassOfService === FlightClassOfServiceEnum.ScootForSure) this.getLowfareEstimate();
    else this._dates$.next(dates);
  }

  private setMinAndMaxDate(): void {
    if (this.dateSelected) {
      const currentDate = new Date();
      const displayMaxCount = this.config.displayCount * this.config.maxNavAllowed;

      //minDate
      this.config.minDate = this.minusDays(this.dateSelected, displayMaxCount - 1);
      if (currentDate > this.config.minDate) this.config.minDate = currentDate;

      //maxDate
      this.config.maxDate = this.addDays(this.dateSelected, displayMaxCount - 1);
    }
  }

  private isValidSelectedDate(date: Date): boolean {
    let isValid = true;
    let message = undefined;

    if (this.isReturn && this.selectedReturnDate && this.selectedFlightType === FlightSelectionTypeEnum.Departing) {
      if (Date.parse(date.toString()) > Date.parse(this.selectedReturnDate.toString())) {
        message = 'notification.text.departDateCannotBeLaterThanReturnDate';
        isValid = false;
      }
    } else if (this.selectedDepartDate && this.selectedFlightType === FlightSelectionTypeEnum.Returning) {
      if (Date.parse(date.toString()) < Date.parse(this.selectedDepartDate.toString())) {
        message = 'notification.text.returnDateCannotBeEarlierThanDepartDate';
        isValid = false;
      }
    }

    if (!isValid && message) {
      this.notificationService.show({
        title: 'notification.title.notification',
        text: message,
        showCancelButton: true,
        showConfirmButton: false
      } as NotificationData);
    }

    return isValid;
  }

  private addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  private minusDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  }

  private getLowfareEstimate() {
    if (
      this.selectedClassOfService === FlightClassOfServiceEnum.ScootForSure &&
      this.flightAvailabilityRequest &&
      this.currentStartDate
    ) {
      let bookingFlightLowfareRequests: BookingFlightLowfareRequest[] = [];
      const flightCriteria =
        this.selectedFlightType == FlightSelectionTypeEnum.Returning
          ? this.flightAvailabilityRequest.flightCriteria[1]
          : this.flightAvailabilityRequest.flightCriteria[0];
      const request = {
        direction: 0,
        currencyCode: this.flightAvailabilityRequest.currencyCode,
        daysForward: this.isMobile ? this.config.mobileDisplayCount : this.config.displayCount,
        daysBackward: 0,
        flightCriteria: [
          {
            origin: flightCriteria.origin,
            destination: flightCriteria.destination,
            departureDate: this.flightSearchService.formatDate(this.currentStartDate, 'yyyy-MM-dd') + 'T00:00:00.000Z'
          }
        ],
        passengerCriteria: this.flightAvailabilityRequest.passengerCriteria,
        flightFare: {
          classOfService: environment.classOfServices.scootForSure
        }
      } as BookingFlightLowfareRequest;

      bookingFlightLowfareRequests.push(request);

      if (this.isMobile) {
        let mobileRequest = cloneDeep(request);
        let departureDate = this.currentStartDate.setDate(this.currentStartDate.getDate() + 14);
        mobileRequest.flightCriteria[0].departureDate =
          this.flightSearchService.formatDate(new Date(departureDate), 'yyyy-MM-dd') + 'T00:00:00.000Z';
        bookingFlightLowfareRequests.push(mobileRequest);
      }

      this.bookingService
        .getFlightLowFare$(bookingFlightLowfareRequests)
        .pipe(take(1))
        .subscribe((response) => {
          this.buildLowFareDates(response);
        });
    } else {
      this.bookingFlightLowfare = null;
      this._dates$.next(this.currentDates);
    }
  }

  private buildLowFareDates(value: BookingFlightLowfare | null) {
    this.bookingFlightLowfare = value;
    this._dates$.next(this.currentDates);
  }

  get isMobile(): boolean {
    return window.innerWidth < AppConstants.MobileWidth.xs;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
