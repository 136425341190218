<div
  #calendarElement
  aria-label="Calendar section"
  [hidden]="!showDatepicker"
  class="tw-z-30 tw-bg-white tw-rounded-lg tw-left-0 xs:tw-absolute xs:tw-top-0 tw-w-full tw-shadow-none xs:tw-mt-16 xs:tw-shadow-lg xs:tw-w-auto tw-max-w-auto"
>
  <div
    aria-label="Calendar header section"
    *ngIf="!isMobile"
    class="tw-p-[15px] tw-flex tw-relative tw-bg-white tw-text-left tw-w-full tw-border-black-40 tw-border-b-[1px] tw-border-solid"
  >
    <div
      aria-label="Calendar title section"
      class="tw-relative tw-absolute tw-items-center tw-justify-center tw-font-bold"
    >
      {{ 'label.book-flight-search.calendar.title' | translate }}
    </div>
    <img
      aria-label="Calendar Close icon"
      class="tw-absolute tw-right-5 tw-top-5 tw-cursor-pointer"
      (click)="onClickClose()"
      src="/assets/icons/svg/exit.svg"
      alt="exit"
    />
  </div>
  <div aria-label="Calendar body section">
    <ngb-datepicker
      #datePicker
      class="tw-min-w-0 tw-w-full xs:tw-min-w-92 xs:tw-w-auto"
      [displayMonths]="displayMonths"
      [navigation]="navigation"
      [showWeekNumbers]="false"
      [firstDayOfWeek]="7"
      [outsideDays]="'hidden'"
      [weekdays]="showWeekDays"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [dayTemplate]="dayT"
      (dateSelect)="onDateSelection($event)"
      [markDisabled]="markDisabled"
    >
      <ng-template ngbDatepickerContent>
        <div *ngFor="let month of datePicker.state.months">
          <div *ngIf="isMonthYearSelected(month, datePicker) && !isMobile" class="ngb-dp-month-name">&nbsp;</div>
          <div
            *ngIf="!isMonthYearSelected(month, datePicker) || isMobile"
            [ngClass]="isMobile ? '' : 'tw-absolute tw-right-[130px]'"
            class="ngb-dp-month-name tw-text-sm tw-font-bold"
          >
            {{ datePicker.i18n.getMonthFullName(month.month) }} {{ month.year }}
          </div>
          <ngb-datepicker-month
            class="ngb-dp-month ngb-datepicker-month"
            [ngClass]="isMonthYearSelected(month, datePicker) ? '' : 'xs:tw-pt-[39px] tw-pt-[0px]'"
            [month]="month"
          >
          </ngb-datepicker-month>
        </div>
      </ng-template>
      <ng-template #dayT let-date>
        <div
          class="custom-day tw-pb-1.5 tw-grow tw-basis-0 tw-h-12 tw-content-center"
          [class.selected]="isSelected(date)"
          [class.range]="isRange(date)"
          [class.faded]="isHovered(date) || isInside(date)"
          (mouseenter)="setHoveredDate(date)"
          (mouseleave)="setHoveredDate(null)"
        >
          <div class="tw-flex-col tw-text-xs tw-justify-center tw-items-center tw-inline-flex">
            <div class="tw-text-center">{{ date.day }}</div>
          </div>
          <div
            class="tw-text-center tw-text-stone-900 tw-text-[10px] tw-font-medium tw-font-['Montserrat'] tw-leading-none"
            attr.aria-label="Calendar day label for {{date.day }}-{{date.month}}-{{date.year}}"
          >
            {{
              (
                isSameDaySelected() ? ''
                : isReturnDaySelected(date) ? 'label.return'
                : isDepartDaySelected(date) ? 'label.depart'
                : ''
              ) | translate
            }}
          </div>
        </div>
      </ng-template>
    </ngb-datepicker>
  </div>
  <div
    aria-label="Calendar footer section"
    id="Calendarfooter"
    *ngIf="!isMobile"
    class="tw-h-[85px] tw-pt-[20px] tw-border-black-40 tw-border-t-[1px] tw-border-solid tw-p-[15px] tw-fixed tw-bottom-0 tw-inset-x-0 xs:tw-static xs:tw-bottom-auto xs:tw-inset-x-auto"
  >
    <div class="tw-flex tw-flex-col tw-gap-5 tw-justify-end">
      <div class="tw-flex tw-mt-auto xs:tw-self-end tw-gap-3.25">
        <button
          aria-label="Calendar Reset button"
          (click)="onClickReset()"
          [disabled]="disabledButton"
          class="tw-btn tw-btn-secondary tw-h-10 tw-px-5 tw-py-2 tw-justify-center tw-flex tw-items-center tw-px-5 md:tw-w-auto"
        >
          {{ 'label.button.reset.label' | translate }}
        </button>
        <button
          aria-label="Calendar Done button"
          (click)="onClickDone()"
          [disabled]="disabledButton"
          class="tw-btn tw-btn-primary tw-h-10 tw-px-5 tw-py-2 tw-justify-center tw-flex tw-items-center tw-px-5 md:tw-w-auto"
        >
          {{ 'label.button.done.label' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
