import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlightSelectedData } from '../../flight.model';
import { FlightClassOfServiceEnum, FlightSelectionTypeEnum } from '../../flight.enum';
import { AppConstants } from '../../../../app.constants';
import { Booking, BookingJourney } from '../../../../booking/booking.model';
import { FlightService } from '../../flight.service';

@Component({
  selector: 'app-flight-selection-result',
  templateUrl: './flight-selection-result.component.html',
  styleUrls: ['./flight-selection-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightSelectionResultComponent {
  readonly flightClassOfServiceEnums = FlightClassOfServiceEnum;

  _selectedFlightData!: FlightSelectedData | null;
  _trip!: Booking | undefined;

  selectedFlight!: FlightSelectedData | null;
  selectedJourney!: BookingJourney | null;
  scootForSureValidMonths = AppConstants.ScootForSureValidMonths;
  isSelected!: boolean | false;
  isSoldOut!: boolean | false;

  @Input()
  get trip(): Booking | undefined {
    return this._trip;
  }
  set trip(value: Booking | undefined) {
    this._trip = value;
    this.isSoldOut = this.trip?.journeys.every((x) => x.soldOut) ?? false;

    if (this._selectedFlightData && this._trip){
      this.setSelectedFlight(this._selectedFlightData);
    }
  }
  @Input() selectedFlightType!: FlightSelectionTypeEnum | null;
  @Input() selectedFlightClassOfService!: FlightClassOfServiceEnum | null;
  @Input()
  get selectedFlightData(): FlightSelectedData | null {
    return this._selectedFlightData;
  }
  set selectedFlightData(value: FlightSelectedData | null) {
    this._selectedFlightData = value;
    this.setSelectedFlight(value);
  }
  @Input() totalVoucherPoints!: number;
  @Input() isValidFlightDate!: boolean;

  constructor(
    private flightService: FlightService
  ) {}

  setSelectedFlight(flight: FlightSelectedData | null): void {    
    let selectedFlight = null;
    let selectedJourney = null;
    this.isSelected = false;

    if (flight && this.trip) {
      const journey = this.trip?.journeys.find((x) => x.journeyKey === flight?.journeyKey);
      const fare = journey?.fares.find((x) => x.fareKey === flight?.fareKey || x.reference === flight?.fareKey);

      if (journey && fare) {
        selectedFlight = {
          journeyKey: flight.journeyKey,
          fareKey: flight.fareKey,
          designator: journey.designator,
          fare: fare,
          departureStation: journey.departureStation,
          arrivalStation: journey.arrivalStation,
          stops: journey.stops,
          segments: journey.segments
        } as FlightSelectedData;

        selectedJourney = {
          fares: [fare],
          designator: selectedFlight.designator,
          departureStation: selectedFlight.departureStation,
          arrivalStation: selectedFlight.arrivalStation,
          segments: selectedFlight.segments,
          stops: selectedFlight.stops
        } as BookingJourney;

        this.isSelected = true;
      }
      else {
        if (this.selectedFlightType === FlightSelectionTypeEnum.Returning) {
          this.flightService.setSelectedReturningFlightData(null);
        } else {
          this.flightService.setSelectedDepartingFlightData(null);
        }
      }
    }

    this.selectedFlight = selectedFlight;
    this.selectedJourney = selectedJourney as BookingJourney;
  }
}
