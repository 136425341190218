import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlightClassOfServiceEnum, FlightFareTypeEnum, FlightSelectionTypeEnum } from '../../../../flight/flight.enum';
import { BookingJourney } from '../../../../../booking/booking.model';

export interface LegData {
  origin: string,
  destination: string,
  standByCount: number,
  seatsLeft: number
}

@Component({
  selector: 'app-flight-card',
  templateUrl: './flight-card.component.html',
  styleUrls: ['./flight-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightCardComponent {
  readonly flightFareType = FlightFareTypeEnum;
  readonly flightClassOfServices = FlightClassOfServiceEnum;

  @Input() journey!: BookingJourney;
  @Input() journeyIndex!: number;
  @Input() selectedFlightType!: FlightSelectionTypeEnum | null;
  @Input() selectedFlightClassOfService!: FlightClassOfServiceEnum | null;
  @Input() totalVoucherPoints!: number;
  @Input() isSelected!: boolean;
}
