import { Component, Input } from '@angular/core';
import { ModalService } from '../../../../../../../shared/components/modal/modal.service';

@Component({
  selector: 'app-flight-detail-view',
  templateUrl: './flight-detail-view.component.html',
  styleUrls: ['./flight-detail-view.component.scss']
})
export class FlightDetailViewComponent {

  @Input() data!: any;

  constructor(private modalService: ModalService) { }

  onCloseModal() {
    this.modalService.hide();
  }

  clickPopupBackgroundEvent(): void {}
}
