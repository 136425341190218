import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { SessionService } from '../session.service';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { SessionExpireService } from '../session-expire.service';
import { ModalService } from '../../shared/components/modal/modal.service';
import { MsalAuthService } from '../../shared/service/msal-auth/msal-auth.service';
import { PageRoute } from '../../shared/enums/page-routes.enum';

@Component({
  selector: 'app-session-expiring',
  templateUrl: './session-expiring.component.html',
  styleUrls: ['./session-expiring.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionExpiringComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  sessionRemainingTime$!: Observable<number>;

  constructor(
    private sessionService: SessionService,
    private sessionExpireService: SessionExpireService,
    private msalAuthService: MsalAuthService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.sessionRemainingTime$ = this.sessionExpireService.remainingTimeBeforeExpiryInSecond$;
    this.sessionExpireService.remainingTimeBeforeExpiryInSecond$
      .pipe(takeUntil(this.destroy$))
      .subscribe((remainingTime) => {
        if (remainingTime <= 0) {
          this.modalService.hide();
        }
      });
  }

  refreshToken(): void {
    this.modalService.hide();
    this.sessionService
      .refreshSessionToken$()
      .pipe(take(1))
      .subscribe(() => {
        this.msalAuthService.acquireTokenSilent();
        this.sessionExpireService.reset();
      });
  }

  logout(): void {
    this.modalService.hide();
    this.sessionService.clearSessionStorage();
    this.sessionExpireService.stop();
    if (this.msalAuthService.hasSSOLogin) 
      this.msalAuthService.logout();
    else
      window.location.href = PageRoute.Home
  }

  clickPopupBackgroundEvent(): void {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
