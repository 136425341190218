import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PageRoute } from '../shared/enums/page-routes.enum';
import { ErrorEnum } from './error.enum';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private readonly _error$ = new BehaviorSubject<HttpErrorResponse>({} as HttpErrorResponse);
  private readonly _errorState$ =  new BehaviorSubject<boolean>(false);

  readonly error$ = this._error$.asObservable();
  readonly errorState$ = this._errorState$.asObservable();

  constructor(private router: Router) {}

  setErrorState(state: boolean): void{
    this._errorState$.next(state);
  }

  setError(error: HttpErrorResponse): void{
    this._error$.next(error);
  }

  handleError(status: ErrorEnum, error: string = ''): void {
    this.setError({ status: status, error: error } as HttpErrorResponse);
    this.setErrorState(true);
    this.router.navigateByUrl(PageRoute.Error);
  }
}