import { Injectable } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { environment } from '../../../../environments/environment';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class MsalAuthService {
  constructor(
    private msalService: MsalService
  ) {}

  login() {
    this.msalService.loginRedirect();
  }

  logout() {
    this.msalService.logoutRedirect();
  }

  setActiveAccount(account: AccountInfo | null){
    this.msalService.instance.setActiveAccount(account);
  }

  acquireTokenSilent() {
    if (this.activeAccount && this.hasSSOLogin) {
      this.msalService.acquireTokenSilent({
        scopes: ["user.read"],
        account: this.activeAccount,
      });
    }
  }

  get activeAccount(): AccountInfo | null {
    return this.msalService.instance.getActiveAccount();
  }

  get authenticated(): boolean {
    return !!this.msalService.instance.getActiveAccount();
  }

  get hasSSOLogin(): boolean {
    return environment.singleSignOn.authority.trim().length > 0;
  }
}
