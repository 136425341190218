import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { ErrorService } from './error.service';
import { Router } from '@angular/router';
import { AppConstants } from '../app.constants';
import { PageRoute } from '../shared/enums/page-routes.enum';
import { SessionExpireService } from '../session/session-expire.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private errorService: ErrorService,
    private sessionExpireService: SessionExpireService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isCriticalApiUrl = this.isCriticalApiUrl(request.url);

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (
          !this.isClientError(err.status) &&
          err.status >= 500 &&
          isCriticalApiUrl &&
          !this.sessionExpireService.isTokenExpired
        ) {
          this.errorService.setError(err);
          this.errorService.setErrorState(true);
          this.router.navigateByUrl(PageRoute.Error);
        }
        return throwError(() => err);
      })
    );
  }

  private isCriticalApiUrl(requestUrl: string): boolean {
    return AppConstants.CriticalAPIUrls.some((criticalApiUrl) => requestUrl.includes(criticalApiUrl));
  }

  private isClientError(statusCode: number): boolean {
    const clientErrorRegex = /4\d{2}/;
    return clientErrorRegex.test(statusCode?.toString());
  }
}
