<div attr.aria-label="{{ selectedFlightType }} class of service toggle" class="tw-items-center tw-px-3.75">
  <div class="scoot-container tw-mx-auto">
    <div class="tw-pt-7 tw-flex tw-justify-between tw-flex-col xs:tw-flex-row tw-gap-1.25 xs:tw-gap-2.5">
      <h3 class="tw-text-stone-900 tw-leading-7 tw-pb-[5px] xs:tw-pb-[15px] xs:tw-pt-[15px]">
        <p
          attr.aria-label="{{ selectedFlightType }} - Select Flight Time section"
          [ngClass]="{
            'tw-h3-sm': isMobile,
            'tw-h3-lg': !isMobile
          }"
        >
          {{ 'label.book-flight-selection.select-flight-time.label' | translate }}
        </p>
      </h3>
      <div
        attr.aria-label="{{ selectedFlightType }} - Toggle Economy and ScootPlus or ScootForSure section"
        class="tw-self-stretch tw-flex-col tw-justify-end tw-items-center tw-lgap-2.5"
      >
        <div class="tw-p-[0.6rem] tw-bg-white tw-shadow-inner tw-rounded-[2.5rem] tw-font-bold tw-text-sm">
          <div class="tw-relative tw-flex tw-justify-between tw-items-center tw-w-full tw-h-full">
            <div
              #EconomyAndScootPlus
              attr.aria-label="{{ selectedFlightType }} - Economy and ScootPlus toggle button"
              (click)="toggleEconomyAndScootPlus()"
              class="tw-justify-start toggle-text"
              [class.selected]="selectedFlightClassOfService === flightClassOfServiceEnums.EconomyAndScootPlus"
            >
              <span
                [ngClass]="{
                  'tw-text-stone-900': selectedFlightClassOfService === flightClassOfServiceEnums.EconomyAndScootPlus
                }"
              >
                {{ 'label.book-flight.economy-and-scootplus.label' | translate }}
              </span>
            </div>
            <div
              #ScootForSure
              attr.aria-label="{{ selectedFlightType }} - ScootForSure toggle button"
              (click)="toggleScootForSure()"
              class="tw-justify-end toggle-text"
              [class.selected]="selectedFlightClassOfService === flightClassOfServiceEnums.ScootForSure"
            >
              <span
                [ngClass]="{
                  'tw-text-stone-900': selectedFlightClassOfService === flightClassOfServiceEnums.ScootForSure
                }"
              >
                {{ 'label.book-flight.scootforsure-uppercase.label' | translate }}
              </span>
            </div>
            <div
              *ngIf="activeTypeIndicatorWidth$ | async as activeTypeIndicatorWidth"
              attr.aria-label="{{ selectedFlightType }} - Active type indicator"
              class="active-indicator"
              [style.width.px]="activeTypeIndicatorWidth"
              [ngClass]="{
                'scootForSure tw-right-[165px]': selectedFlightClassOfService === flightClassOfServiceEnums.ScootForSure
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
