<div
  *ngIf="isVisible"
  attr.aria-label="{{ selectedFlightType }} - SFS notification section"
  class="tw-flex tw-flex-row tw-bg-yellow-20 tw-px-[20px] tw-gap-2 tw-rounded-2xl tw-mt-[20px]"
>
  <div class="tw-hidden xs:tw-flex tw-justify-start tw-items-center">
    <img
      attr.aria-label="{{ selectedFlightType }} - SFS notification icon"
      class="tw-mx-auto tw-cursor-pointer"
      src="assets/icons/svg/sfs-notification.svg"
    />
  </div>
  <div
    attr.aria-label="{{ selectedFlightType }} - SFS notification text"
    class="tw-flex tw-grow tw-shrink tw-items-center tw-py-[20px] tw-text-[15px]"
  >
    {{
      'label.book-flight-selection.notification-scootforsure-available-seats.label'
        | translate : { maxAvailableSeats: maxAvailableSeats }
    }}
  </div>
  <div class="tw-flex tw-justify-end tw-items-center tw-w-[35px] tw-relative">
    <img
      attr.aria-label="{{ selectedFlightType }} - SFS notification close icon"
      class="tw-cursor-pointer tw-absolute tw-top-[25px] xs:tw-relative xs:tw-top-0"
      src="/assets/icons/svg/exit.svg"
      (click)="onClose()"
    />
  </div>
</div>
