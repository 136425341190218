import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { SessionService } from '../../session/session.service';
import { AppConstants } from '../../app.constants';
import { FlightService } from '../../booking/flight/flight.service';
import { PageRoute } from '../../shared/enums/page-routes.enum';
import { Currency } from '../../shared/service/resources/resource.model';
import { ExternalRate } from '@it-application-hub/bookings/lib/booking-models/booking-state.models';

@Component({
  selector: 'app-currency-selection',
  templateUrl: './currency-selection.component.html',
  styleUrls: ['./currency-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrencySelectionComponent implements OnInit {
  @Input() currencies!: Currency[];
  @Input() externalRates!: ExternalRate[];
  @Output() closeCurrencySelection = new EventEmitter<void>();
  popularCurrencies!: Currency[];
  otherCurrencies!: Currency[];
  isMouseInside = true;
  isMobile = false;
  searchCurrency = '';
  isShowMore = true;
  readonly pageRoute = PageRoute;
  constructor(
    private sessionService: SessionService,
    private flightService: FlightService
  ) {}
  @HostListener('document:click', ['$event'])
  clickout() {
    if (!this.isMouseInside) {
      this.closeCurrencyModal();
    }
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.isMobile = window.innerWidth < AppConstants.MobileWidth.xs;
  }

  ngOnInit(): void {
    this.isMobile = window.innerWidth < AppConstants.MobileWidth.xs;
    this.currencies = this.currencies.filter(
      (x) =>
        this.externalRates.some(
          (y) =>
            y.fromCurrencyCode === this.sessionService.getCurrencyCodeFromSessionStorage() &&
            y.toCurrencyCode === x.currencyCode
        ) || x.currencyCode === this.sessionService.getCurrencyCodeFromSessionStorage()
    );
    this.initializeCurrencies();
    this.setSelectedCurrencyDescription();
  }

  setSelectedCurrency(currency: Currency) {
    if (this.selectedCurrencyCode !== currency.currencyCode) {
      this.sessionService.setSelectedCurrencyCode(currency.currencyCode);
      this.flightService.setSelectedCurrency(currency.currencyCode);
      this.closeCurrencyModal();
    }
  }

  closeCurrencyModal() {
    this.closeCurrencySelection.emit();
  }

  onSearchCurrency() {
    this.popularCurrencies = this.filterCurrencies(AppConstants.CurrencyGroupType.Popular);
    this.otherCurrencies = this.filterCurrencies(AppConstants.CurrencyGroupType.Others);
    if (!this.searchCurrency) {
      this.initializeCurrencies();
    }
  }

  onToggleShowMoreShowLess() {
    this.isShowMore = !this.isShowMore;
    if (!this.isShowMore) {
      this.otherCurrencies = [];
    } else {
      this.initializeCurrencies();
    }
  }

  private filterCurrencies(groupType: number): Currency[] {
    return this.currencies.filter(
      (x) =>
        (x.currencyCode.toLowerCase().includes(this.searchCurrency.toLowerCase()) ||
          x.description.toLowerCase().includes(this.searchCurrency.toLowerCase())) &&
        x.groupType === groupType
    );
  }

  private setSelectedCurrencyDescription(): void {
    const currencyDescription = this.currencies.find(
      (x) => x.currencyCode === this.sessionService.getSelectedCurrencyCode()
    )?.description;
    if (currencyDescription) {
      this.sessionService.setSelectedCurrencyDescription(currencyDescription);
    }
  }

  private initializeCurrencies(): void {
    this.popularCurrencies = this.currencies.filter((x) => x.groupType === AppConstants.CurrencyGroupType.Popular);
    this.otherCurrencies = this.isShowMore
      ? this.currencies.filter((x) => x.groupType === AppConstants.CurrencyGroupType.Others)
      : [];
  }

  get selectedCurrencyCode(): string {
    return this.sessionService.getSelectedCurrencyCode() ?? AppConstants.DefaultCurrencyCode;
  }
  
  get selectedCurrencyDescription(): string {
    return this.sessionService.getSelectedCurrencyDescription() ?? AppConstants.DefaultCurrencyDescription;
  }
}
