import { Injectable } from '@angular/core';
import { LoginRequest, LoginResponse, MyIDTravelRequest, UserInfo } from './account.model';
import { HttpClient, HttpContext, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, take, tap, throwError } from 'rxjs';
import { SessionService } from '../session/session.service';
import { MyIDTravelLogInResponse, SessionResponse } from '../session/session.model';
import { AppConstants } from '../app.constants';
import { SKIP_AUTHORIZATION } from '../shared/model/common.model';
import { SessionExpireService } from '../session/session-expire.service';
import { LocalStorageConstant } from '../session/session.enum';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private _userInfo!: UserInfo | null;

  isLogInCalled = false;

  constructor(
    private httpClient: HttpClient,
    private sessionService: SessionService,
    private sessionExpireService: SessionExpireService
  ) {}

  login$(request: LoginRequest | null): Observable<LoginResponse> {
    if (!request) {
      const idToken = this.sessionService.getCurrentUserIdTokenFromLocalStorage();
      request = { idToken: idToken ? idToken : AppConstants.DefaultUserEmail };
    }
    
    this.sessionService.setIdTokenToLocalStorage(request.idToken);
    this.isLogInCalled = true;

    return this.httpClient
      .post<LoginResponse>('/account/login', request, { 
        context: new HttpContext()
        .set(SKIP_AUTHORIZATION, true)
      })
      .pipe(
        take(1),
        tap((response: LoginResponse) => {
          if (response) {
            this.setUserInfo(response.userInfo);
            this.setSessionIfo(response.token);
            this.sessionService.setSession(response.token);
            this.sessionService.setSessionToLocalStorage(response.token);
            this.sessionExpireService.startTimerCountdown(response.token.expiresIn);
          }
        }),
        catchError((error) => this.handleError(error))
      );
  }

  logout$(): Observable<boolean> {
    this.isLogInCalled = false;
    return this.httpClient.post<boolean>('/account/logout', null);
  }

  myIDTravelLogIn$(request: MyIDTravelRequest): Observable<MyIDTravelLogInResponse> {
    return this.httpClient.post<MyIDTravelLogInResponse>('/myidtravel/login', request);
  }

  setSessionIfo(token: SessionResponse) {
    localStorage.setItem(LocalStorageConstant.sessionToken, token.accessToken);
    localStorage.setItem(LocalStorageConstant.expiresIn, JSON.stringify(token.expiresIn));
  }

  clearSessionIfo() {
    localStorage.removeItem(LocalStorageConstant.sessionToken);
    localStorage.removeItem(LocalStorageConstant.expiresIn);
    localStorage.removeItem(LocalStorageConstant.idToken);
    this.clearUserInfo();
  }

  setUserInfo(userInfo: UserInfo | null) {
    this._userInfo = userInfo;
    localStorage.setItem(LocalStorageConstant.userInfo, JSON.stringify(userInfo)); 
  }

  getUserInfo(): UserInfo | null {
    try {
      if (!this._userInfo) {
        let cached = localStorage.getItem(LocalStorageConstant.userInfo) ?? '';
        this._userInfo = cached ? JSON.parse(cached) : null;
      }
  
      return this._userInfo;
    }
    catch(error) {
      return null;
    }
  }

  clearUserInfo() {
    this._userInfo = null;
    this.isLogInCalled = false;
    localStorage.removeItem(LocalStorageConstant.userInfo); 
  }

  get validUser(): boolean {
    const userInfo = this.getUserInfo();
    return !!userInfo;
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(() => error);
  }
}
