import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ErrorLabelEnum } from '../../../error/error.enum';

@Injectable({
  providedIn: 'root'
})
export class FlightSelectionErrorHandlerService {
  private readonly _errors$ = new BehaviorSubject<string[]>([]);
  private currentErrors: string[] = [];

  readonly errors$ = this._errors$.asObservable();

  emitErrors(messages: string[]): void {
    const newErrors = messages.filter((message) => !this.currentErrors.includes(message));
    this.currentErrors = [...this.currentErrors, ...newErrors];
    this._errors$.next(this.currentErrors);
  }

  emitError(message: string): void {
    this.emitErrors([message]);
  }

  removeError(message: string): void {
    this.currentErrors = this.currentErrors.filter((error) => error !== message);
    this._errors$.next(this.currentErrors);
  }

  clearErrors(): void {
    this.currentErrors = [];
    this.emitErrors([]);
  }

  hasErrors(): boolean {
    return this.currentErrors.some(p => p != ErrorLabelEnum.DefaultSystemError);
  }
}
